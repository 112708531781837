import React, {useState} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import {ACCESS_TOKEN, KOR_REX, SPC_REX, START_NUMBER_REX, UPPER_CASE_REX} from '../../../const/const';
import {EventSource, EventSourcePolyfill} from 'event-source-polyfill';
import {useRecoilState} from 'recoil';
import {appProcessState} from '../../../recoil/checkListState';
import {useToastPopup} from '../../../hooks/useToastPopup';
import {BUILD_HOST} from '../../../server/host';
import {Helmet} from 'react-helmet-async';
const CheckListRe3 = () => {
  const [searchParams] = useSearchParams();
  const navigator = useNavigate();
  const toastHook = useToastPopup();
  const [appName, setAppName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [appProcess, setAppProcess] = useRecoilState(appProcessState);

  /**
   * 앱을 생성하는 함수
   * 1. /start API 호출 (fetch 사용)
   * 2. 응답 데이터에서 buildId 및 stackName 추출
   * 3. 추출한 파라미터로 /monitor/progress API를 통해 SSE 연결
   */
  const onClickCreateApp = async (e) => {
    e.preventDefault();

    // 입력값 검증
    if (!appName) {
      toastHook.addToastPopup("앱 이름은 필수입니다.", "error");
      return;
    }
    if (SPC_REX.test(appName)) {
      toastHook.addToastPopup("앱 이름에 특수기호는 불가능 합니다.", "error");
      return;
    }
    if (!projectName) {
      toastHook.addToastPopup("프로젝트이름은 필수입니다.", "error");
      return;
    }
    if (SPC_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 특수기호는 불가능 합니다.", "error");
      return;
    }
    if (UPPER_CASE_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 대문자는 불가능 합니다.", "error");
      return;
    }
    if (KOR_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명에 한글은 불가능 합니다.", "error");
      return;
    }
    if (START_NUMBER_REX.test(projectName)) {
      toastHook.addToastPopup("프로젝트명 첫번째 글자는 영어로 구성되야합니다.", "error");
      return;
    }

    try {
      // 1. /start API 호출 (fetch 사용)
      const payload = {
        appName,
        codeBuild: {
          projectName,
          iconUrl: "https://mbaas-file-bucket.s3.ap-northeast-2.amazonaws.com/default-logo.png",
          moduleTemplateCode: searchParams.get("s"), // 템플릿 코드는 searchParams의 "s" 값 사용
        },
      };

      const response = await fetch(`${BUILD_HOST}/start`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // JSON 형식 지정
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
        },
        body: JSON.stringify(payload),
      });

      const jsonResponse = await response.json();

      // 2. 응답 데이터 처리
      // 응답 예시:
      // {
      //   "result": "SUCCESS",
      //   "data": {
      //     "apkCodeBuildResponseDTO": { ... },
      //     "webViewCodeBuildResponseDTO": {
      //         "codeBuildId": "mbaas-webview-builder:...416",
      //         "buildStatus": "IN_PROGRESS"
      //     },
      //     "cloudFormationStackName": "finaltesting-web-stack"
      //   }
      // }
      const { webViewCodeBuildResponseDTO, cloudFormationStackName } = jsonResponse.data;
      const buildId = webViewCodeBuildResponseDTO.codeBuildId; // 사용된 buildId
      const stackName = cloudFormationStackName;               // 사용된 stackName

      if (!buildId || !stackName) {
        toastHook.addToastPopup("빌드 ID 또는 Stack Name을 가져올 수 없습니다.", "error");
        return;
      }

      // 3. /monitor/progress API를 통한 SSE 연결
      const eventSource = new EventSourcePolyfill(
          `${BUILD_HOST}/monitor/progress/${buildId}/${stackName}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
            },
            heartbeatTimeout: 10 * 60 * 1000,
            withCredentials: true,
          }
      );

      eventSource.onopen = () => {
        // SSE 연결 성공 시 상태 업데이트 및 페이지 이동
        setAppProcess((prev) => ({
          ...prev,
          isBuilding: true,
        }));
        navigator("/checklist/appcreate");
      };

      // SSE 'progress' 이벤트 수신 처리


      eventSource.addEventListener("progress", (e) => {
        // 예시: "Progress: 35% - mbaas-webview-builder:ID12345 : 앱 빌드 진행 중: 초기 설정, 코드 컴파일 및 테스트 실행 중"
        const rawData = e.data;
        const trimmedData = rawData.replace("Progress:", "").trim();
        const parts = trimmedData.split(" - ");
        if (parts.length < 2) return;
        // 첫번째 부분: "35%"
        const percentStr = parts[0].trim();
        const percent = parseInt(percentStr.replace("%", "").trim());
        // 두번째 부분: "[식별자] : 상세 메시지"
        const messagePart = parts[1].trim();
        const msgParts = messagePart.split(" : ");
        if (msgParts.length < 2) return;
        // 식별자 사용 여부에 따라 표시할 메시지 구성
        const identifier = msgParts[0].trim();
        const detailedStatus = msgParts.slice(1).join(" : ").trim();

        setAppProcess((prev) => ({
          ...prev,
          message: `${detailedStatus} `,
          percent,
        }));

        if (detailedStatus.indexOf("완료") !== -1) {
          setAppProcess({
            isBuilding: false,
            message: "앱 생성 완료",
            percent: 100,
          });
          toastHook.addToastPopup("앱 생성이 완료되었습니다.", "success");
          eventSource.close();
        }
      });

      eventSource.onerror = (e) => {
        console.error("SSE Error:", e);
        eventSource.close();
        setAppProcess({
          isBuilding: false,
          message: "",
          percent: 0,
        });
        toastHook.addToastPopup("앱 생성에 문제가 생겼습니다.", "error");
      };

    } catch (error) {
      console.error("앱 생성 API 오류:", error);
      toastHook.addToastPopup("앱 생성 요청 중 오류 발생", "error");
    }
  };

  return (
      <div className='checkListReWrap'>
        <Helmet>
        <title>AiAppㅣ앱 생성ㅣ체크리스트 3</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="앱 생성 체크리스트 세번째 단계입니다. 프로젝트명을 최종 확인 및 설정하여 앱 개발을 완료하세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
        <main className='checkListCon'>
          <div className='wrap'>
            <div className='leftCon'>
              <div className='sbox'>
                <h2>프로젝트명을 작성해주세요.</h2>
                <div>
                  <h3>앱 이름</h3>
                  <input value={appName} onChange={(e) => { setAppName(e.target.value) }} type="text" placeholder='앱이름을 입력해주세요.' />
                </div>
                <div>
                  <h3>프로젝트 명</h3>
                  <input type="text" value={projectName} onChange={(e) => { setProjectName(e.target.value) }} placeholder='프로젝트명을 입력해주세요.' />
                  <p className='description'>영문 소문자와 숫자로만 가능하며, 추후 변경이 불가능하니 신중히 작성해주세요. (최소 5 ~12글자) <br />관리자 페이지 로그인 시 프로젝트명이 필요합니다.</p>
                </div>
              </div>
            </div>
            <div className='rightCon'>
              <div className='sboxWrap'>
                <div className='sbox'>
                  <h3>프로젝트 명은 무엇인가요?</h3>
                  <p>프로젝트명은 앱을 생성(빌드)할때 필요한 <span className='bold point'>앱 패키지명</span>으로, 영문 소문자와 숫자로 최소 5글자에서 12글자로 생성이 가능하며, 앱 생성이후 수정이 불가능하므로 신중히 작성해 주시기 바랍니다.<br />
                    프로젝트명은 이후에 <span className='bold point'>앱 관리자 로그인시 로그인 코드로 사용</span>되므로, 앱 생성자는 하위 앱 관리자를 지정 후 하위 관리자 역시 로그인 코드로 필요합니다. <br />
                    앱을 생성 후에도 앱 생성자(슈퍼관리자)의 마이페이지에서 확인하실 수도 있습니다. </p>
                </div>
                <div className='sbox'>
                  <h3>왜 프로젝트 명이 필요한가요?</h3>
                  <p>하나의 계정에 하나의 앱을 관리하면 문제가 없지만, <span className='bold point'>하나의 계정으로 앱 관리를 1개 이상하시는 분들을 구분</span>할 수 있게 해주며, 보안 코드로서의 역할도 수행하므로 잃어버리거나 다른 곳에 노출이 되지않게 주의 부탁드립니다</p>
                </div>
                <div className='sbox'>
                  <h3>프로젝트명 작성 예시</h3>
                  <p>프로젝트명은 브랜드명, 앱 이름 등 관련 요소들을 영문으로 기억하기 쉬운 단어의 조합을 추천드립니다</p>
                  <img src="../img/prohectImg01.png" alt="프로젝트명 작성 예시 사진" />
                </div>
                <div className='sbox'>
                  <h3>프로젝트명은 어디서 사용하나요?</h3>
                  <p>앱 관리자 로그인시 프로젝트 명 입력이 필요합니다. (아이디 저장시 같이 저장됩니다)</p>
                  <img src="../img/prohectImg02.png" alt="프로젝트명 사용 예시 사진" />
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer>
          <div className='btnWrap'>
            <Link to={-1}><button className='off'>이전</button></Link>
            <button onClick={onClickCreateApp}>생성</button>
          </div>
        </footer>
      </div>
  )
}


export default CheckListRe3