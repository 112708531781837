import React from 'react'
import { Form, Link, redirect, useActionData } from 'react-router-dom'
import { ACCESS_TOKEN, POST_METHOD, SAVE_ID } from '../../const/const'
import { fn_Debug, makeParamsFromFormData } from '../../util/util';
import { loginQuery } from '../../queries/memberQueries';
import Input from '../atom/Input';
import { HOST } from '../../server/host';
import { Helmet } from 'react-helmet-async';

/**
 * router.js에 등록시키는 action. POST 요청이 들어올 시 해당 액션이 실행된다.
 * @returns redirect를 return 시킴으로써 해당 URL로 바로 이동시킨다.
 */
export const loginAction = 
    (queryClient) => async({request}) => {
        const formData = await request.formData();
        const params = makeParamsFromFormData(formData);
        const errors = {};

        if(!params.memberEmail) {
            errors.memberEmail = "이메일을 입력해주세요."
            return errors;
        }
        else if(typeof params.memberEmail !== "string" || !params.memberEmail.includes("@") ) {
            errors.memberEmail = "이메일 형식을 확인해주세요.";
            return errors;
        }
        else if (!params.memberPassword) {
            errors.memberPassword = "비밀번호를 입력해주세요."
            return errors;
        }

        const response = await queryClient.fetchQuery(loginQuery(params))

        fn_Debug(response)

        if(response.result === "SUCCESS") {
            localStorage.setItem(ACCESS_TOKEN, response.data.accessToken);

            if(params.saveId) {
                localStorage.setItem(SAVE_ID, params.memberEmail);
            } else {
                localStorage.removeItem(SAVE_ID);
            }
            return redirect("/");
        } else {
            errors.loginError = response.message
        }
        return errors;
    }

const Login = () => {
    const errors = useActionData();

    return (
        <main>
        <Helmet>
            <title>AiAppㅣ로그인</title>
            <meta name="author" content="mBaaS Inc." />
            <meta name="description" content="이메일과 비밀번호로 간편하게 로그인하여 앱 개발과 다양한 서비스 기능을 이용해보세요."/>
            <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
            <meta property="og:type" content="website" />
            <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="og:url" content="https://aiapp.help/" />
            <meta property="og:images" content="../../../public/img/opengraph.jpg" />
            <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
            <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
            <meta property="twitter:image" content="../../../public/img/opengraph.jpg"/>
            <meta property="twitter:url" content="https://aiapp.help/" />
            <meta property="twitter:card" content="summary_large_image" />
        </Helmet>
            <div className="loginWrap bg1">
                <div className="loginCon">
                    <div>
                        <h2>Login</h2>
                        <p>소셜 로그인으로 간편하게 가입하고<br /> 무료로 앱을 생성해 보세요.</p>
                        <div className='emailLogin'>
                            <Form method={POST_METHOD}>
                                <Input 
                                    type="text" 
                                    name='memberEmail' 
                                    placeholder='이메일 주소를 입력해주세요.' 
                                    defaultValue={localStorage.getItem(SAVE_ID)?.replaceAll("\"", "")}/>
                                <Input 
                                    type="password" 
                                    name='memberPassword' 
                                    placeholder='비밀번호를 입력해주세요.'/>
                                <div className='checkboxArea'>
                                    <label htmlFor="IDsave">
                                        <input 
                                            type="checkbox" 
                                            name='saveId' 
                                            id="IDsave"
                                            defaultChecked={localStorage.getItem(SAVE_ID)}/>
                                        <p>아이디저장</p>
                                    </label>
                                </div>
                                <Input type={"hidden"} error={errors?.memberEmail}/>
                                <Input type={"hidden"} error={errors?.memberPassword}/>
                                <Input type={"hidden"} error={errors?.loginError}/>
                                <button className='on'>로그인</button>
                            </Form>
                            <div className='find d-flex gap20 justify-content-center'>
                                <Link to={"/findid"}><button>아이디 찾기</button></Link>
                                <Link to={"/findpw"}><button>비밀번호 찾기</button></Link>
                            </div>
                            <div className='line'></div>
                            <div>
                                <p>아직 회원이 아닌신가요?</p>
                                <Link to={"/term"}><button className='on'>회원가입 하러가기</button></Link>
                            </div>
                        </div>
                        <div className='snsLoginWrap'>
                            <p>간편 로그인</p>
                            {/* 해당링크로 이동시킬시 서버에서 구글 인증을 진행후 redirect url로 이동 */}
                            <Link to={`${HOST}/oauth2/authorization/google`}><button className="snsLogin">Sign in with Google</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Login
